// 引入抽屉详情组件
import AuthorizationDrawer from "./components/authorizationDrawer/index.vue";
// 引入api
import { fun_PostAction } from "@/api";
// 引入获取keys方法
import { fun_GetKeys } from "@/utils/validate";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 列表loading初始状态
      loading: false,
      // 表头按钮组
      btnList: [
        {
          name: "添加",
          type: "success",
          icon: "document-add"
        },
        {
          name: "修改",
          type: "primary",
          icon: "edit"
        },
        {
          name: "删除",
          type: "danger",
          icon: "delete"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "菜单名称",
          prop: "title",
          align: "left"
        },
        {
          label: "请求地址",
          prop: "path"
        },
        {
          label: "组件名称",
          prop: "name"
        },
        {
          label: "组件路径",
          prop: "component"
        },
        {
          label: "排序",
          prop: "trun"
        }
      ],
      // 表格数据
      tableData: [],
      // 勾选的数据
      multipleSelection: []
    };
  },

  // 注册组件
  components: {
    // 抽屉详情组件
    AuthorizationDrawer
  },

  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },

  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 滕威
     */
    async fun_GetList() {
      // 打开loading
      this.loading = true;
      // 请求，获取结果集
      let res = await fun_PostAction("/sysOperation/operationListInit");
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 回显table
        this.tableData = data;
      } else {
        // 提示请求失败消息
        this.$message.error(msg);
      }
      // 关闭loading
      this.loading = false;
    },
    /**
     * 点击修改按钮
     * @author 滕威
     */
    fun_Edit() {
      // 判断是否勾选
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        // 勾选超过一条
        if (this.multipleSelection.length > 1) {
          // 提示，并清除选中数据
          this.$alert("只能选择一条数据！", "warning").then(() => {
            // 清除选中数据
            this.$refs.list.$refs.multipleTable.clearSelection();
          });
        } else {
          // 打开抽屉
          this.$refs.authorizationDrawer.drawerStatus = true;
          // 执行获取详情方法
          this.$refs.authorizationDrawer.fun_GetDetails(
            this.multipleSelection[0]
          );
        }
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 点击删除按钮
     * @author 滕威
     */
    fun_Del() {
      // 判断是否勾选
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        // 勾选超过一条
        if (this.multipleSelection.length > 1) {
          // 勾选多条时，提示
          this.$alert("只能选择一条数据！", "warning").then(() => {
            // 清除选中数据
            this.$refs.list.$refs.multipleTable.clearSelection();
          });
        } else {
          // 勾选一条
          // 弹出询问confirm
          this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            // 获取id
            const id = fun_GetKeys(this.multipleSelection);
            // 发起请求，获取结果集
            fun_PostAction("/sysOperation/deleteOperation?id=" + id).then(
              res => {
                // 解构res.data
                const { result, msg } = res.data;
                // 判断请求是否成功
                if (result) {
                  // 查询列表
                  this.fun_GetList();
                  // 提示删除成功
                  this.$message.success(msg);
                } else {
                  // 请求失败，提示信息
                  this.$message.error(msg);
                }
              }
            );
          });
        }
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 表头按钮事件
     * @params e 按钮name
     * @author 滕威
     */
    fun_click(e) {
      switch (e) {
        case "添加":
          // 打开抽屉
          this.$refs.authorizationDrawer.drawerStatus = true;
          // 添加时，清空id
          this.$refs.authorizationDrawer.form.id = null;
          break;
        case "修改":
          // 调用修改方法
          this.fun_Edit();
          break;
        default:
          // 调用删除方法
          this.fun_Del();
      }
    },
    /**
     * 勾选事件
     * @params e 选中的数据
     * @author 滕威
     */
    fun_HandleSelectionChange(e) {
      // 将勾选的value值赋给multipleSelection数组
      this.multipleSelection = e;
    }
  }
};
