// 引入api
import { fun_GetAction, fun_PostAction } from "@/api";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      type: "add",
      menuRadio: "1",
      // 抽屉状态
      drawerStatus: false,
      // 抽屉确定按钮loading状态
      loading: false,
      // 查询表单字段
      form: {
        id: null, // id
        title: null, // 菜单名称
        path: null, // 菜单路径
        pid: null, // 上级菜单
        name: null, // 组件名称
        component: null, // 组件路径
        icon: null, // 菜单图标
        trun: null // 排序
      },
      // 详情表单基础数据
      formConfigList: [
        {
          label: "菜单名称：",
          value: "title",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入菜单名称",
            trigger: "blur"
          }
        },
        {
          label: "菜单路径：",
          value: "path",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入菜单路径",
            trigger: "blur"
          }
        },
        // {
        //   label: "上级菜单：",
        //   type: "slot",
        //   slotName: "pid",
        //   value: "pid",
        //   required: true,
        //   rule: {
        //     required: true,
        //     message: "请选择上级菜单",
        //     trigger: "change"
        //   }
        // },
        {
          label: "组件名称：",
          value: "name",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入组件名称",
            trigger: "blur"
          }
        },
        {
          label: "组件路径：",
          value: "component",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入组件路径",
            trigger: "blur"
          }
        },
        {
          label: "菜单图标：",
          value: "icon",
          type: "input"
        },
        {
          label: "排序：",
          value: "trun",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入排序",
            trigger: "blur"
          }
        }
      ],
      // 后台返回错误对象
      errRules: {}
    };
  },

  // 自定义方法
  methods: {
    /**
     * 获取详情
     * @author 滕威
     */
    async fun_GetDetails(e) {
      this.type = "edit";
      if (e.pid === "0") {
        this.menuRadio = "1";
        // 选择的是一级菜单就删除formConfigList下标为2后面的obj
        // this.formConfigList.splice(2, 1);
      } else {
        this.menuRadio = "2";
        // 定义上级菜单slot
        const obj = {
          label: "上级菜单：",
          type: "slot",
          slotName: "pid",
          value: "pid",
          required: true,
          rule: {
            required: true,
            message: "请选择上级菜单",
            trigger: "change"
          }
        };
        // 在formConfigList下标为2后面添加obj
        this.formConfigList.splice(2, 0, obj);
      }
      this.form.id = e.id;
      // 请求，获取结果集
      let res = await fun_GetAction("/sysOperation/detail?id=" + this.form.id);
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 回显表单
        for (let key in this.form) {
          this.form[key] = data[key];
        }
      } else {
        // 请求失败，提示错误信息
        this.$message.error(msg);
      }
    },
    /**
     * 表单提交
     * @author 滕威
     */
    fun_SubmitForm() {
      // 校验
      this.$refs.form.$refs.enInform.validate(valid => {
        if (valid) {
          // 发起请求，获取结果集
          fun_PostAction("/sysOperation/saveOrUpdateOperation", this.form).then(
            res => {
              // 解构res.data
              const { result, msg } = res.data;
              // 判断请求是否成功
              if (result) {
                // 请求一览查询接口
                this.$parent.fun_GetList();
                // 关闭弹窗
                this.drawerStatus = false;
                // 保存成功
                this.$message.success(msg);
              } else {
                // 请求失败，提示信息
                this.$message.error(msg);
              }
            }
          );
        } else {
          // 校验不通过，返回false，页面给出前端提示
          return false;
        }
      });
      // 关闭抽屉
    },
    /**
     * 抽屉关闭时执行
     * @author 滕威
     */
    fun_DrawerClose() {
      // 清除表单验证和数据
      this.$refs.form.$refs.enInform.resetFields();
      // 关闭时，清除勾选
      this.$parent.$refs.list.$refs.multipleTable.clearSelection();
      // 默认选中一级菜单
      this.menuRadio = "1";
      // 如果表单有上级菜单
      if (this.formConfigList[2].value === "pid") {
        // 则删除
        this.formConfigList.splice(2, 1);
      }
      // 修改type为add
      this.type = "add";
    },
    /**
     * 切换一级菜单、二级菜单时触发
     * @author 滕威
     */
    fun_MenuRadio(e) {
      if (this.type === "add") {
        // 判断选中的是二级菜单
        if (e === "2") {
          // 定义上级菜单slot
          const obj = {
            label: "上级菜单：",
            type: "slot",
            slotName: "pid",
            value: "pid",
            required: true,
            rule: {
              required: true,
              message: "请选择上级菜单",
              trigger: "change"
            }
          };
          // 在formConfigList下标为2后面添加obj
          this.formConfigList.splice(2, 0, obj);
        } else {
          // 选择的是一级菜单就删除formConfigList下标为2后面的obj
          this.formConfigList.splice(2, 1);
        }
      }
      return;
    },
    /**
     * 选择上级菜单下拉框事件
     * @param {e} 选中的value
     * @author 滕威
     */
    fun_SelectChange(e) {
      // 回显
      this.form.pid = e.code;
    }
  }
};
